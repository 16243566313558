import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import SectionContent from "../components/containers/section-content"
import SectionBackground from "../components/containers/section-background"
import Section from "../components/containers/section"
import { Columns, Content, Rows } from "../components/grid"
import Heading from "../components/content/heading"
import Copy from "../components/content/copy"
import Card from "../components/containers/card"
import Button from "../components/interactive/button"
import TryUsBar from "../components/sections/try-us-bar"
import HeadingAccent from "../components/accents/heading-accent"
import PardotForm from "../components-smart/forms/pardot-form"
import CtaGroup from "../components/containers/cta-group"

function CostPage({ data, location }) {
  const { header, footerButtons, mainContent, seo, topText } =
    data.strapiPageCost

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <Section wave="sword" theme="dark">
        <SectionContent hero className="mt-md lap-pb-xl">
          <Rows gap="xxl" className="mb-md ">
            <Columns count="2" className="px-sm tab-px-none">
              <Content gap="xl">
                <Heading level={1}>{header.headingText}</Heading>
                <Copy>
                  <p>{header.postHeadingText}</p>
                </Copy>
              </Content>
              <Card styleType="white" className="text--center">
                <Heading level={2} className="text--center pt-xs">
                  Get Pricing
                </Heading>
                <Copy>
                  <p className="px-xl text--xs">
                    By submitting this form, you are agreeing to Lunio’s Privacy
                    Policy and Terms of Service.
                  </p>
                </Copy>
                <PardotForm
                  height="190px"
                  url="https://info.ppcprotect.com/l/931773/2021-10-18/42z8"
                />
              </Card>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionBackground>
          <HeadingAccent top={48} color="ragnarok" />
        </SectionBackground>
        <SectionContent paddingTop="xs" paddingBot="none">
          <Columns count="2" alignment="center">
            <Content>
              <Heading level={2} preheading={topText.heading.preHeading}>
                {topText.heading.primaryHeading}
              </Heading>
              <Copy contentToParse={topText.text} />
              <CtaGroup>
                <Button color={topText.button.color} href={topText.button.link}>
                  {topText.button.text}
                </Button>
              </CtaGroup>
            </Content>
            <Content>
              <StaticImage
                src="../images/ppc-protect-cost/books-stacked.png"
                alt="books stacked"
              />
            </Content>
          </Columns>
        </SectionContent>
      </Section>
      <Section className="overflow--visible">
        <SectionContent paddingTop="md" paddingBot="none">
          <Columns count="1,2">
            <aside
              className="overflow--visible sticky section--dark"
              style={{
                outline: "16px solid #E2E2E3",
                borderRadius: "20px",
                margin: "16px",
                background: "inherit",
              }}
            >
              <Card
                styleType="solid"
                className="pl-md pr-lg card--double-border section--dark width--full"
                style={{
                  backgroundColor: "#30154F",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 150px)",
                }}
              >
                <Heading level={3}>Chapter Titles</Heading>
                {mainContent.map(({ anchorLink, menuChapterTitle }, index) => (
                  <Link
                    key={`main-${index}`}
                    id={index}
                    className="side-menu text---color-white text--normal text--sm text--line-height-md"
                    style={{ "--link-color": "#fff" }}
                    to={`#${anchorLink}`}
                  >
                    {menuChapterTitle}
                  </Link>
                ))}
              </Card>
            </aside>
            <Content className="px-lg pt-lg tab-pt-none" data-gap="xxl">
              {mainContent.map((content, index) => (
                <div className="mainContent">
                  <Content gap="md">
                    <Heading level={2} key={index} id={content.anchorLink}>
                      {content.heading}
                    </Heading>

                    <Copy
                      contentToParse={content.text}
                      key={`${index}-content`}
                    />
                  </Content>
                </div>
              ))}
              <CtaGroup>
                <Button
                  color={footerButtons.endOfContentButton.color}
                  href={footerButtons.endOfContentButton.link}
                >
                  {footerButtons.endOfContentButton.text}
                </Button>
              </CtaGroup>
            </Content>
          </Columns>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query PageCostQuery {
    strapiPageCost {
      footerButtons {
        endOfContentButton {
          color
          link
          text
        }
      }
      mainContent {
        anchorLink
        heading
        menuChapterTitle
        text
      }
      seo {
        metaDesc
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      topText {
        button {
          color
          link
          text
          type
        }
        heading {
          preHeading
          primaryHeading
        }
        text
      }
      header {
        headingText
        postHeadingText
      }
    }
  }
`

export default CostPage
